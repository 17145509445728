import vcbioticCover from '../assets/vcbiotic/cover.png'
import vcbioticIngredientCN from '../assets/vcbiotic/ingredient-cn.jpg'
import vcbioticIngredientEN from '../assets/vcbiotic/ingredient-en.jpg'
import vcbiotic1 from '../assets/vcbiotic/1.jpg'
import vcbiotic2 from '../assets/vcbiotic/2.jpg'
import vcbiotic3 from '../assets/vcbiotic/3.jpg'
import vcbiotic4 from '../assets/vcbiotic/4.jpg'
import vcbiotic5 from '../assets/vcbiotic/5.jpg'
import vcbiotic6 from '../assets/vcbiotic/6.jpg'
import vcbiotic7 from '../assets/vcbiotic/7.jpg'
import vcbiotic8 from '../assets/vcbiotic/8.jpg'
import vcbiotic9 from '../assets/vcbiotic/9.jpg'

import vcmmuneCover from '../assets/vcmmune/cover.png'
import vcmmuneIngredientCN from '../assets/vcmmune/ingredient-cn.jpg'
import vcmmuneIngredientEN from '../assets/vcmmune/ingredient-en.jpg'
import vcmmune1 from '../assets/vcmmune/1.jpg'
import vcmmune2 from '../assets/vcmmune/2.jpg'
import vcmmune3 from '../assets/vcmmune/3.jpg'
import vcmmune4 from '../assets/vcmmune/4.jpg'
import vcmmune5 from '../assets/vcmmune/5.jpg'
import vcmmune6 from '../assets/vcmmune/6.jpg'
import vcmmune7 from '../assets/vcmmune/7.jpg'
import vcmmune8 from '../assets/vcmmune/8.jpg'
import vcmmune9 from '../assets/vcmmune/9.jpg'

import vcllagenCover from '../assets/vcllagen/cover.png'
import vcllagenIngredientCN from '../assets/vcllagen/ingredient-cn.jpg'
import vcllagenIngredientEN from '../assets/vcllagen/ingredient-en.jpg'
import vcllagen1 from '../assets/vcllagen/1.jpg'
import vcllagen2 from '../assets/vcllagen/2.jpg'
import vcllagen3 from '../assets/vcllagen/3.jpg'
import vcllagen4 from '../assets/vcllagen/4.jpg'
import vcllagen5 from '../assets/vcllagen/5.jpg'
import vcllagen6 from '../assets/vcllagen/6.jpg'
import vcllagen7 from '../assets/vcllagen/7.jpg'
import vcllagen8 from '../assets/vcllagen/8.jpg'
import vcllagen9 from '../assets/vcllagen/9.jpg'

import vcblockCover from '../assets/vcblock/cover.png'
import vcblockIngredientCN from '../assets/vcblock/ingredient-cn.jpg'
import vcblockIngredientEN from '../assets/vcblock/ingredient-en.jpg'
import vcblock1 from '../assets/vcblock/1.jpg'
import vcblock2 from '../assets/vcblock/2.jpg'
import vcblock3 from '../assets/vcblock/3.jpg'
import vcblock4 from '../assets/vcblock/4.jpg'
import vcblock5 from '../assets/vcblock/5.jpg'
import vcblock6 from '../assets/vcblock/6.jpg'
import vcblock7 from '../assets/vcblock/7.jpg'
import vcblock8 from '../assets/vcblock/8.jpg'
import vcblock9 from '../assets/vcblock/9.jpg'

import vcmasculiCover from '../assets/vcmasculi/cover.png'
import vcmasculiIngredientCN from '../assets/vcmasculi/ingredient-cn.jpg'
import vcmasculiIngredientEN from '../assets/vcmasculi/ingredient-en.jpg'
import vcmasculi1 from '../assets/vcmasculi/1.jpg'
import vcmasculi2 from '../assets/vcmasculi/2.jpg'
import vcmasculi3 from '../assets/vcmasculi/3.jpg'
import vcmasculi4 from '../assets/vcmasculi/4.jpg'
import vcmasculi5 from '../assets/vcmasculi/5.jpg'
import vcmasculi6 from '../assets/vcmasculi/6.jpg'
import vcmasculi7 from '../assets/vcmasculi/7.jpg'
import vcmasculi8 from '../assets/vcmasculi/8.jpg'
import vcmasculi9 from '../assets/vcmasculi/9.jpg'

import vcfeminiCover from '../assets/vcfemini/cover.png'
import vcfeminiIngredientCN from '../assets/vcfemini/ingredient-cn.jpg'
import vcfeminiIngredientEN from '../assets/vcfemini/ingredient-en.jpg'
import vcfemini1 from '../assets/vcfemini/1.jpg'
import vcfemini2 from '../assets/vcfemini/2.jpg'
import vcfemini3 from '../assets/vcfemini/3.jpg'
import vcfemini4 from '../assets/vcfemini/4.jpg'
import vcfemini5 from '../assets/vcfemini/5.jpg'
import vcfemini6 from '../assets/vcfemini/6.jpg'
import vcfemini7 from '../assets/vcfemini/7.jpg'
import vcfemini8 from '../assets/vcfemini/8.jpg'
import vcfemini9 from '../assets/vcfemini/9.jpg'

export const vcbioticImage =[vcbioticCover, vcbiotic1, vcbiotic2, vcbiotic3, vcbiotic4, vcbiotic5, vcbiotic6, vcbiotic7, vcbiotic8, vcbiotic9, vcbioticIngredientEN, vcbioticIngredientCN ]
export const vcmmuneImage =[vcmmuneCover, vcmmune1, vcmmune2, vcmmune3, vcmmune4, vcmmune5, vcmmune6, vcmmune7, vcmmune8, vcmmune9, vcmmuneIngredientEN,  vcmmuneIngredientCN]
export const vcllagenImage =[vcllagenCover, vcllagen1, vcllagen2, vcllagen3, vcllagen4, vcllagen5, vcllagen6, vcllagen7, vcllagen8, vcllagen9,  vcllagenIngredientEN, vcllagenIngredientCN]
export const vcblockImage =[vcblockCover, vcblock1, vcblock2, vcblock3, vcblock4, vcblock5, vcblock6, vcblock7, vcblock8, vcblock9, vcblockIngredientEN, vcblockIngredientCN ]
export const vcmasculiImage =[vcmasculiCover, vcmasculi1, vcmasculi2, vcmasculi3, vcmasculi4, vcmasculi5, vcmasculi6, vcmasculi7, vcmasculi8, vcmasculi9, vcmasculiIngredientEN, vcmasculiIngredientCN ]
export const vcfeminiImage =[vcfeminiCover, vcfemini1, vcfemini2, vcfemini3, vcfemini4, vcfemini5, vcfemini6, vcfemini7, vcfemini8, vcfemini9, vcfeminiIngredientEN, vcfeminiIngredientCN ]
