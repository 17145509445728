import React from "react";
import { Row, Col } from "react-bootstrap";
import contactImage from "../assets/contact-image-new.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import shopeeLogoImage from "../assets/shopee-logo-contact.jpg";
import lazadaLogoImage from "../assets/lazada-logo-contact.jpg";

const ContactUs = () => {
  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-top">
          <div className="about-us-title">Contact Us</div>
          <div className="about-us-line"></div>
        </div>
        <p className="about-us-explain">
          " We'd love to hear from you! Whether you have questions, feedback, or
          need assistance, feel free to reach out to us through the provided
          contact information or connect with us on our social media platforms. "
        </p>

        <Col md="6" className="about-us-left">
          <div className="design-box"></div>
          <div className="white-div"> <img
            className="contact-image"
            src={contactImage}
            alt="contact-img"
          ></img></div>

        </Col>
        <Col md="6" className="about-us-right">
          <div className="contact-us-social">
            <p className="sub-title">Get In Touch</p>
            <a
              href="https://www.facebook.com/qnenutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <FacebookIcon className="social-icon" />
            </a>
            <a
              href="https://instagram.com/qnenutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <InstagramIcon
                className="social-icon"
                style={{ margin: "0px 1px" }}
              />
            </a>
            <a
              href="https://wa.link/528hd0"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <WhatsAppIcon className="social-icon" />
            </a>
            <a
              href="https://shopee.com.my/qnenutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <img src={shopeeLogoImage} className="social-shopee-img" alt='shopee'></img>
            </a>
            <a
              href="https://www.lazada.com.my/shop/qne-nutrition"
              target="_blank"
              rel="noreferrer"
              className="social-link"
            >
              {" "}
              <img src={lazadaLogoImage} className="social-lazada-img" alt='lazada'></img>
            </a>
          </div>
          <div className="contact-us-company-info">
            <p className="sub-title">Company Information</p>
            <LocationOnIcon className="company-info-icon" style={{ marginTop: '0' }} />
            <p className='company-info-text'>
              QNE Nutrition Sdn. Bhd.<br />202101020217 (1420517-X)
            </p>
            <p className='company-info-text' style={{ marginTop: '10px' }}>
              No 15, Lorong Perkasa Jaya, Taman Perkasa Jaya, 13000 Butterworth,
              Pulau Pinang, Malaysia.
            </p>
            <MailOutlineIcon className="company-info-icon" />
            <a className='company-info-text-email' href="mailto:qnenutrition@gmail.com">qnenutrition@gmail.com</a>
            <SmartphoneIcon className="company-info-icon" />
            <a className='company-info-text-email' href="tel:60107871413">+6010-7871413</a>
            <p></p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
