import React from "react";
import { Row, Col } from "react-bootstrap";
import aboutImage from "../assets/about-image.jpg";

const AboutUs = () => {

  return (
    <div className="about-us">
      <Row className="about-us-wrapper">
        <div className="about-us-top">
          <div className="about-us-title">About Us</div>
          <div className="about-us-line"></div>
        </div>
        <p className="about-us-explain">
          " Embark on a Journey to Better Health with QNE Nutrition: Where Your
          Well-being Matters "
        </p>

        <Col md="6" className="about-us-left">
          <div className="design-box"></div>
          <img className="about-image" src={aboutImage} alt="about-img"></img>
        </Col>
        <Col md="6" className="about-us-right">

          {/* in order to use the css style */}
          <div className="contact-us-company-info">
            <p className="company-info-text">
              Welcome to QNE Nutrition, a premier food and health supplement
              company founded by Malaysian experts. We provide top-quality,
              natural, and affordable products. Our rigorous sourcing and
              testing ensure the highest standards. With expert collaborations,
              we offer tailored solutions for your nutrition needs. Transparency
              is vital to us, providing accurate information. Our dedicated
              customer support team is here to assist you. Choose QNE Nutrition
              for premium nutrition, empowering your wellness journey.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutUs;
