import React, { useState, useEffect, useRef } from "react";
import { CarouselProvider, Slider, Slide, Dot, Image, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { vcbioticImage, vcmmuneImage, vcllagenImage, vcblockImage, vcmasculiImage, vcfeminiImage } from "./image";
import { data } from "./data";
import { Link } from "react-router-dom";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CancelIcon from '@mui/icons-material/Cancel';
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import shopeeLogo from "../assets/shopee-logo.jpg";
import lazadaLogo from "../assets/lazada-logo.jpg";
import whatsappLogo from "../assets/whatsapp-logo.jpg";
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const [productImage, setProductImage] = useState(vcbioticImage);
  const [product, setProduct] = useState("vcbiotic");
  const [descriptionLanguage, setDescriptionLanguage] = useState("EN");
  const [descriptionContent, setDescriptionContent] = useState();
  const [render, setRender] = useState(true);
  const [shopeeLink, setShopeeLink] = useState("https://shopee.com.my/product/436453012/10531193659/");
  const [lazadaLink, setLazadaLink] = useState("https://www.lazada.com.my/products/qne-vcbiotic-enzyme-detox-fiber-100-natural-ingredients-i3202480327-s16254501963.html");

  // ----------------- change description content --------------------------------------------
  
  useEffect(() => {
    setDescriptionContent(data[product][descriptionLanguage]);
  }, [product, descriptionLanguage]);

  // ----------------- re-render images when changing product----------------------------------

  useEffect(() => {
    setRender(false);
    setTimeout(() => {
      setRender(true);
    }, 10);
  }, [product]);

  // ----------------- show full Image -------------------------------------------------------

  const [showImage, setShowImage] = useState(false);
  const imageRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // change url with query enable us to click back without change the base url
  const navigate = useNavigate();
  const handleClick = ()=>{
    setShowImage(true);
    setCurrentImageIndex(imageRef.current.state.currentSlide)
    navigate('/?query=enlargeImage'); 
  }

  // when back button of the browser is clicked
  window.addEventListener('popstate',()=>{
    setShowImage(false);
  });

  const handleClose = ()=>{
    setShowImage(false);
  }

  // fake back button on full screen
  const handleFakeBack = ()=>{
    const realBack = document.querySelector(".real-back")
    realBack.click()
    // the click does not change the state immediately, 
    // so we need a delay function, so that it runs after the state has been updated.
    setTimeout(() => {
      setCurrentImageIndex(imageRef.current.state.currentSlide);
    }, 0);
  }

  // fake next button on full screen
  const handleFakeNext = ()=>{
    const realNext = document.querySelector(".real-next")
    realNext.click()
    setTimeout(() => {
      setCurrentImageIndex(imageRef.current.state.currentSlide);
    }, 0);
  }
  
  // change the image display in full screen
  useEffect(() => {
    const currentImageFull = document.querySelector(".current-image-full");
    if (currentImageFull) {
      currentImageFull.src = productImage[currentImageIndex]
    }
  }, [currentImageIndex, productImage]);

  document.addEventListener('touchend', ()=>{
    if(imageRef.current){
      setCurrentImageIndex(imageRef.current.state.currentSlide)
    }
  })

  // ----------------- // -------------------------------------------------------

  const [carouselWidth, setCarouselWidth] = useState();
  const [carouselHeight, setCarouselHeight] = useState();

  useEffect(() => {
    
  const carouselWidth = document.querySelector('.carousel-wrapper').clientWidth
  setCarouselWidth(carouselWidth);
  const carouselHeight = document.querySelector('.carousel-wrapper').clientHeight
  setCarouselHeight (carouselHeight)
    
  }, []);

  return (
    <div className="home">
    {/* ---------------------full screen image-------------------------------------- */}
      {showImage && (
        <div class="modal-row">
          <div className="close-wrapper">
          <CancelIcon onClick={handleClose}
              className="cancel-icon"
            />          
            </div>
       
          <div class="modal-content">
            <img
              src={productImage[currentImageIndex]}
              alt="img"
              className="current-image-full"
            />
          </div>
          <div className="fake-back-wrapper" onClick={handleFakeBack}>
            <KeyboardDoubleArrowLeftIcon
              className="fake-back"
              
            />
          </div>
          <div className="fake-next-wrapper" onClick={handleFakeNext}>
            <KeyboardDoubleArrowRightIcon
              className="fake-next"
              
            />
          </div>
        </div>
      )}
      {/* --------------------- product btn -------------------------------------- */}

      <div className="product-btn-wrapper">
        <Link
          className="product-btn"
          style={product === "vcbiotic" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("vcbiotic");
            setProductImage(vcbioticImage);
            setShopeeLink(
              "https://shopee.com.my/product/436453012/10531193659/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/qne-vcbiotic-enzyme-detox-fiber-100-natural-ingredients-i3202480327-s16254501963.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          QNE VCBIOTIC
        </Link>
        <Link
          className="product-btn"
          style={product === "vcmmune" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("vcmmune");
            setProductImage(vcmmuneImage);
            setShopeeLink(
              "https://shopee.com.my/product/436453012/21008695668/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/qne-vcmmune-immune-booster-tiger-milk-mushroom-vitamin-c-c-100-natural-ingredients-i3202723517-s16256250978.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          QNE VCMMUNE
        </Link>
        <Link
          className="product-btn"
          style={product === "vcllagen" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("vcllagen");
            setProductImage(vcllagenImage);
            setShopeeLink(
              "https://shopee.com.my/product/436453012/15986686218/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/qne-vcllagen-collagen-peptide-whitening-anti-aging-100-natural-ingredients-i3202823177-s16259752678.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          QNE VCLLAGEN
        </Link>
        <Link
          className="product-btn"
          style={product === "vcblock" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("vcblock");
            setProductImage(vcblockImage);
            setShopeeLink(
              "https://shopee.com.my/product/436453012/22718806144/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/qne-vcblock-carbohydrate-blocker-fat-blocker-fat-burner-100-natural-ingredients-i3406984180-s18327343953.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          QNE VCBLOCK
        </Link>
        <Link
          className="product-btn"
          style={product === "vcmasculi" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("vcmasculi");
            setProductImage(vcmasculiImage);
            setShopeeLink(
              "https://shopee.com.my/product/436453012/25581576154/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/qne-vcmasculi-mens-health-prostate-health-i3812568800-s22092761979.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          QNE VCMASCULI
        </Link>
        <Link
          className="product-btn"
          style={product === "vcfemini" ? { color: "#0991099d" } : {}}
          onClick={() => {
            setProduct("vcfemini");
            setProductImage(vcfeminiImage);
            setShopeeLink(
              "https://shopee.com.my/product/436453012/19585005304/"
            );
            setLazadaLink(
              "https://www.lazada.com.my/products/qne-vcfemini-womens-health-menstruation-i3812545926-s22093423901.html"
            );
          }}
        >
          <div className="icon-wrapper">
            <ArtTrackIcon />
          </div>
          QNE VCFEMINI
        </Link>
      </div>
      {/* --------------------- product carousel -------------------------------------- */}

      <div className="carousel-wrapper">
        {!render &&(<div style={{width:`${carouselWidth}px`, height:`${carouselHeight}px`, backgroundColor:'white'}}></div>)}
        {render && (
          <CarouselProvider
            naturalSlideWidth={20}
            naturalSlideHeight={20}
            orientation="horizontal"
            hasMasterSpinner="true"
            interval="5000"
            isPlaying="true"
            totalSlides={productImage.length}
          >
            <Slider>
              <div className="slider-top-space"></div>
              {productImage.map((image, index) => (
                  <Slide index={index} key={index} ref={imageRef}>
                    <img
                      onClick={handleClick}
                      className="d-block w-100 product-card-image"
                      src={image}
                      alt='product-info-and-review'
                    />

                    <ButtonBack className="real-back">
                      <KeyboardDoubleArrowLeftIcon />
                    </ButtonBack>
                    <ButtonNext className="real-next">
                      <KeyboardDoubleArrowRightIcon />
                    </ButtonNext>
                  </Slide>
                ))}
            </Slider>

            <div className="all-dots">
              {productImage.map((image, index) => (
                  <Dot slide={index} key={index}>
                    <Image
                      className="d-block w-100 product-card-image image-choice"
                      src={image}
                    />
                  </Dot>
                ))}
            </div>
          </CarouselProvider>
        )}
      </div>
      {/* --------------------- description -------------------------------------- */}

      <div className="desc-wrapper">
        <div className="description-option">
          <p className="description-option-text">DESCRIPTION</p><p className="description-option-semicolon">:</p>
          <Link
            className="description-option-btn"
            style={descriptionLanguage === "EN" ? { color: "#0991099d" } : {}}
            onClick={() => {
              setDescriptionLanguage("EN");
            }}
          >
            ENG
          </Link>
          <Link
            className="description-option-btn"
            style={descriptionLanguage === "CN" ? { color: "#0991099d" } : {}}
            onClick={() => {
              setDescriptionLanguage("CN");
            }}
          >
            中文
          </Link>
        </div>
        <div className="ecommerce-option">
          <p className="description-option-text">E-COMMERCE</p><p className="description-option-semicolon">:</p>
          <Link className="shopee-wrapper" to={shopeeLink} target="_blank">
            <img src={shopeeLogo} alt='shopee-logo'></img>
          </Link>
          <Link className="shopee-wrapper" to={lazadaLink} target="_blank">
            <img src={lazadaLogo} alt='lazada-logo'></img>
          </Link>
        </div>

        <div className="description-content">
          {descriptionContent &&
            descriptionContent.map((desc) => {
              if (desc === " ") {
                return <div className="empty-line"></div>;
              } else {
                return <h6 className="text-line">{desc}</h6>;
              }
            })}
          {descriptionLanguage === "EN" && (
            <div>
              <p className="wholesale-text">
                To find out more about our wholesale price,{" "}
              </p>
              <Link
                className="whatsapp-wrapper"
                to="https://wa.link/hn4ycg"
                target="_blank"
              >
                contact us.<img src={whatsappLogo} alt='whatsapp-logo'></img>
              </Link>
            </div>
          )}
          {descriptionLanguage === "CN" && (
            <div>
              <p className="wholesale-text">若想要了解更多关于我们的批发价, </p>
              <Link
                className="whatsapp-wrapper"
                to="https://wa.link/ykqw2s"
                target="_blank"
              >
                请联系我们。<img src={whatsappLogo} alt='whatsapp-logo'></img>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
