import React from "react";
import "./style/index.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <div className="page-body">
          {/* <Suspense fallback={<div></div>}> */}
            {/* Switch replaced with Routes in newer react-router-dom */}
            <Routes>
            {/* component replaced with element and {Home} replaced with {<Home/>} in newer react-router-dom */}
              <Route exact path="/" element={<Home/>}/>
              <Route exact path="/aboutus" element={<AboutUs/>}/>
              <Route exact path="/contactus" element={<ContactUs/>}/>
            </Routes>
          {/* </Suspense> */}
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
